// OTHER
$frame-modal-header-height-desktop: 56px;
$frame-modal-header-height-mobile: 44px;
$frame-modal-body-desktop-padding: 32px;
/* stylelint-disable */
//px with 0 need to calc() works
$frame-modal-title-desktop-padding: 0px;
$frame-modal-mobile-padding-top: 10px;
$frame-modal-mobile-padding-bottom: 4px;
$frame-modal-info-filter-height: 40px;
$frame-modal-info-filter-top-space: 8px;
$frame-modal-info-filter-padding-top: 9px;

//default color
$snack-bar-background: #333333;
$snack-bar-text-color: #ffffffde;
$snack-bar-action-color: #02feff;








