@import "style-tenant-styling";
@import "tenant-variable";
@import "style/material";

@font-face {
  font-family: "Roboto Flex";
  src: url('./assets/fonts/robotoFlex/static/RobotoFlex-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: "Roboto Flex Condensed Black Italic";
  src: url('./assets/fonts/robotoCondensed/static/RobotoCondensed-BlackItalic.ttf');
  font-display: swap;
}

@font-face {
  font-family: Rubik;
  src: url('./assets/fonts/rubic/static/Rubik-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: Rubik-Bold;
  src: url('./assets/fonts/rubic/static/Rubik-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: Rubik-Medium;
  src: url('./assets/fonts/rubic/static/Rubik-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: Rubik-Light;
  src: url('./assets/fonts/rubic/static/Rubik-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: SuperbetSans;
  src: url('./assets/fonts/SuperSans/SuperbetSans-Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: SuperbetSans-Bold;
  src: url('./assets/fonts/SuperSans/SuperbetSans-Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: SuperbetSans-Black;
  src: url('./assets/fonts/SuperSans/SuperbetSans-Black.otf');
  font-display: swap;
}

@font-face {
  font-family: SuperbetSans-Regular;
  src: url('./assets/fonts/SuperSans/SuperbetSans-Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url('./assets/fonts/Inter/Inter-Regular.otf');
  font-display: swap;
}

@font-face {
  font-family: Inter-Bold;
  src: url('./assets/fonts/Inter/Inter-Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: Inter-Black;
  src: url('./assets/fonts/Inter/Inter-Black.otf');
  font-display: swap;
}

@font-face {
  font-family: Inter-Medium;
  src: url('./assets/fonts/Inter/Inter-Medium.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Inter Semi Bold';
  src: url('./assets/fonts/Inter/Inter-SemiBold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Flex Super Condensed Black Italic';
  src: url('assets/fonts/Super_Roboto/RobotoFlex-SuperCondensedBlackItalic.otf');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Flex Super Condensed Black';
  src: url('assets/fonts/Super_Roboto/RobotoFlex-SuperCondensedBlack.otf');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Flex Condensed Bold';
  src: url('assets/fonts/Super_Roboto/RobotoCondensed-Bold.otf');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Flex Super Extended Black';
  src: url('assets/fonts/Super_Roboto/RobotoFlex-SuperExtendedBlack.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Flex Super Extended Black Italic';
  src: url('assets/fonts/Super_Roboto/RobotoFlex-SuperExtendedBlackItalic.otf');
  font-display: swap;
}

@if $tenantId == 'TENANT_ID' {
  @font-face {
    font-family: Inter-Light;
    src: url('./assets/fonts/Inter/Inter-Light.otf');
    font-display: swap;
  }
}

body {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  position: relative;
  overflow: hidden;
  min-height: 99dvh;

  .admin-button {
    background: var(--primary-color-200)!important;
    border-radius: 8px;
    font-weight: 700;
    color: var(--light-color);
    font-size: 14px;
  }

  .container {
    width: 90%;
    margin: 0 auto;
    display: block;

    &.desktop-style {
      width: 1133px;
    }
  }

  a {
    text-decoration: none;
  }
}

body::after {
  content: '';
  display: block;
}

@media screen and (width >= 700px) {
  .frame-overlay-panel {
    margin-bottom: 32px !important;
  }
}

.frame-overlay-panel {
  width: 100%;
  max-width: 720px !important;
  max-height: 96vh;

  .mat-bottom-sheet-container {
    overflow: visible;
    min-width: auto;
    height: fit-content;
    max-height: 100%;
    width: 100%;
    padding: 0 !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--iframev2-light-color);
    background: var(--iframev2-dark-100);

    hun-main-modal {
      min-height: calc(100vh - 25px);
    }
  }
}

@media screen and (width <= 720px) {
  .frame-v3-overlay-panel, .frame-v3-streak-overlay-panel {
    align-items: flex-end !important;

    .mat-bottom-sheet-container {
      width: 100% !important;
      min-width: unset !important;
      max-width: unset !important;
      border-radius: 16px 16px 0 0 !important;
    }
  }

}

.frame-v3-streak-overlay-panel {
  width: 100%;
  max-width: 720px !important;
  max-height: 93vh;
  height: calc(93vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-bottom-sheet-container {
    min-width: 384px;
    max-width: calc(100vw - 128px);
    overflow: visible;
    height: fit-content;
    max-height: 97%;
    width: 720px;
    padding: 8px;
    border-radius: 16px;
    color: #2B343A;
    background: #FFF;

    hun-main-modal {
      min-height: calc(100vh - 25px);
    }
  }
}

@media (width >= 720px) and (height <= 812px) {
  .frame-v3-streak-overlay-panel {
    height: 100vh !important;

    .mat-bottom-sheet-container {
      height: 100vh !important;
    }
  }
}

.frame-v3-overlay-panel {
  width: 100%;
  max-width: 720px !important;
  max-height: 93vh;
  height: calc(93vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-bottom-sheet-container {
    min-width: 384px;
    max-width: calc(100vw - 128px);
    overflow: visible;
    height: fit-content;
    max-height: 97%;
    width: 720px;
    padding: 8px;
    border-radius: 16px;
    color: var(--iframe-v3-overlay-panel-color);
    background: var(--iframe-v3-overlay-panel-background);

    hun-main-modal {
      min-height: calc(100vh - 25px);
    }
  }
}

@media screen and (width <= 720px) {
  .add-name-modal-v3 {
    align-self: flex-end !important;
    max-width: 100% !important;
    width: 100%;

    .mat-mdc-dialog-container {
      width: 100% !important;

      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          border-radius: 16px 16px 0 0 !important;
        }
      }
    }
  }
}

.add-name-modal-v3 {
  .mat-mdc-dialog-container {
    width: 400px;
    height: auto;

    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        border-radius: 16px;
        padding: 24px;
        background-color: var(--iframe-v3-confirm-popup-background-color);
      }
    }
  }

  &.streak {
    .mat-mdc-dialog-container {
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          padding: 0 !important;
        }
      }
    }
  }
}

@media screen and (width <= 700px) {
  .confirmation-modal-v3, .prize-modal-v3 {
    max-width: unset !important;
    width: 100%;

    .mat-mdc-dialog-container {
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          border-radius: 16px 16px 0 0 !important;
        }
      }
    }
  }
}

@media screen and (height <= 350px) {
  .prize-modal-v3 {
    margin-top: 0 !important;
  }
}

.confirmation-modal-v3 {
  .mat-mdc-dialog-container {
    width: 400px;
    height: 404px;

    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        border-radius: 16px;
        padding: 24px;
        background-color: var(--iframe-v3-confirm-popup-background-color);
      }
    }
  }
}

@media screen and (width <= 720px) {
  .banned-users-v3 {
    width: 100% !important;

    .mat-mdc-dialog-container {
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          border-radius: 16px 16px 0 0 !important;
        }
      }
    }
  }
}

.banned-users-v3 {
  .mat-mdc-dialog-container {
    width: 400px;
    height: 362px;

    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        border-radius: 16px;
        padding: 24px;
        background-color: var(--iframe-v3-confirm-popup-background-color);
      }
    }
  }

  &.segment {
    .mat-mdc-dialog-container {
      height: unset;
    }
  }

  &.streak {
    .mat-mdc-dialog-container {
      .mdc-dialog__container {
        .mat-mdc-dialog-surface {
          padding: 0 !important;
        }
      }
    }
  }
}

.prize-modal-v3 {
  width: 560px !important;

  .mat-mdc-dialog-container {
    .mdc-dialog__container {
      .mat-mdc-dialog-surface {
        border-radius: 16px;
        padding: 24px;
        background-color: var(--iframe-v3-confirm-popup-background-color);
      }
    }
  }
}

@media screen and (width <= 700px) {
  .banned-users-v3 {
    max-width: unset !important;

    .mat-mdc-dialog-container {
      width: inherit;
    }
  }

  .confirmation-modal-v3 {
    .mat-mdc-dialog-container {
      width: inherit;
    }
  }
}

.global-link-color {
  color: var(--iframev2-yellow);
}

.cdk-overlay-container {
  z-index: 99999;
}

.prize-space-wrapper {
  white-space: pre-wrap;
}

@media (pointer: coarse), (hover: none) {
  .mobile-title[title] {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }

  .mobile-title[title]:focus::after {
    content: attr(title);
    position: absolute;
    top: 90%;
    color: #000;
    background-color: #fff;
    border: 1px solid;
    width: fit-content;
    padding: 3px;
    font-size: 10px;
  }

}

// fix for fonts in safari
body.safari-browser * {
  -webkit-font-smoothing: antialiased;
  font-synthesis: none !important;
}

#initial-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
}

.spinner {
  width: 25px;
  height: 25px;
  border: 3px solid #fddc2a;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@include getMainStyleByTenantId($tenantId)
