@import "../style/ux-variables";

.cdk-virtual-scroll-data-source-example {
  .example-viewport {
    height: 421px;
    width: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    .cdk-virtual-scroll-content-wrapper {
      display: flex;
      flex-direction: row;

      .example-item {
        hun-card {
          padding-right: 7px;
        }
      }
    }
  }
}

.cdk-virtual-scroll-data-source-example .example-item {
  height: 100%;
}

::ng-deep.cdk-virtual-scroll-content-wrapper {
  .example-item:last-child {
    &.last {
      hun-card {
        .mat-card {
          margin-right: 0
        }
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: var(--dark-color-200);
  }
}

.snackbar {
  position: absolute !important;
  bottom: 34px !important;
  left: 48% !important;
  transform: translateX(-50%) !important;
  max-width: 97% !important;
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    left: 300px !important;
    width: auto !important;
    min-width: 127px !important;
  }
}

.mdc-snackbar {
  .mdc-snackbar__surface {
    background: $snack-bar-background;

    .mat-mdc-snack-bar-label {
      font-size: 12px;
      font-weight: 400;
      color: $snack-bar-text-color;
      font-family: Inter-Bold, sans-serif;

      .mat-mdc-snack-bar-actions {
        .mat-mdc-snack-bar-action  {
          font-size: 12px;
          font-weight: 400;
          font-family: Inter-Bold, sans-serif;
          color: $snack-bar-action-color;
        }
      }
    }
  }
}
